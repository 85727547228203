.nobreak {
  white-space: nowrap;
}
.nohyphens {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
.hideme {
  display: none !important;
}
::-moz-selection {
  background: #73a6cb;
  color: #fff;
}
::selection {
  background: #73a6cb;
  color: #fff;
}
* {
  -webkit-tap-highlight-color: rgba(115, 166, 203, 0.1);
  box-sizing: border-box;
}
main {
  display: block;
}
html {
  -webkit-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
body {
  letter-spacing: calc((0.875rem + 0.125
                                 * (100vw - 18.75rem)
                                 / (75))
                                 / 1000 * 0);
  font-size: 0.875rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  color: #4b4b4b;
  font-family: "Merriweather", sans-serif;
  -webkit-font-feature-settings: "liga", "kern";
          font-feature-settings: "liga", "kern";
  -webkit-font-kerning: normal;
          font-kerning: normal;
  font-smoothing: antialiased;
  font-weight: 400;
  hyphenate-limit-chars: 6 3 2;
  hyphenate-limit-lines: 2;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  line-height: 2;
  margin: 0 auto;
  max-width: 120rem;
  position: relative;
  text-rendering: geometricPrecision;
}
@media (min-width: 18.75rem) {
  body {
    font-size: calc(0.875rem + 0.125
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media (min-width: 93.75rem) {
  body {
    font-size: 1rem;
  }
}
body.no_scroll {
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  word-wrap: break-word;
}
h1 {
  letter-spacing: calc((1.4375rem + 0.75
                                 * (100vw - 18.75rem)
                                 / (75))
                                 / 1000 * 0);
  font-size: 1.4375rem;
  color: #4b4b4b;
  font-family: "Palanquin Dark", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 1em;
}
@media (min-width: 18.75rem) {
  h1 {
    font-size: calc(1.4375rem + 0.75
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media (min-width: 93.75rem) {
  h1 {
    font-size: 2.1875rem;
  }
}
h2 {
  letter-spacing: calc((1.1875rem + 0.25
                                 * (100vw - 18.75rem)
                                 / (75))
                                 / 1000 * 0);
  font-size: 1.1875rem;
  color: #4b4b4b;
  font-family: "Palanquin Dark", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 1em;
  padding-top: 1em;
}
@media (min-width: 18.75rem) {
  h2 {
    font-size: calc(1.1875rem + 0.25
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media (min-width: 93.75rem) {
  h2 {
    font-size: 1.4375rem;
  }
}
h3 {
  letter-spacing: calc((1rem + 0.1875
                                 * (100vw - 18.75rem)
                                 / (75))
                                 / 1000 * 0);
  font-size: 1rem;
  color: #4b4b4b;
  font-family: "Palanquin Dark", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 1em;
  padding-top: 1em;
}
@media (min-width: 18.75rem) {
  h3 {
    font-size: calc(1rem + 0.1875
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media (min-width: 93.75rem) {
  h3 {
    font-size: 1.1875rem;
  }
}
h4 {
  letter-spacing: calc((0.875rem + 0.125
                                 * (100vw - 18.75rem)
                                 / (75))
                                 / 1000 * 0);
  font-size: 0.875rem;
  color: #4b4b4b;
  font-family: "Palanquin Dark", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 1em;
}
@media (min-width: 18.75rem) {
  h4 {
    font-size: calc(0.875rem + 0.125
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media (min-width: 93.75rem) {
  h4 {
    font-size: 1rem;
  }
}
h5 {
  letter-spacing: calc((0.875rem + 0.125
                                 * (100vw - 18.75rem)
                                 / (75))
                                 / 1000 * 0);
  font-size: 0.875rem;
  color: #4b4b4b;
  font-family: "Palanquin Dark", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 18.75rem) {
  h5 {
    font-size: calc(0.875rem + 0.125
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media (min-width: 93.75rem) {
  h5 {
    font-size: 1rem;
  }
}
h6 {
  height: 0;
  margin: 0;
  overflow: hidden;
  width: 0;
}
p {
  margin: 0 0 1em;
}
a {
  color: #73a6cb;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a:active,
a.active {
  color: #4b4b4b;
  outline: 0;
}
a:focus {
  outline: 0.1875rem solid rgba(115, 166, 203, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a:focus[data-focus-method="mouse"],
a:focus[data-focus-method="touch"] {
  outline: 0;
}
a:hover {
  text-decoration: none;
}
a[tabindex="-1"]:focus {
  outline: 0;
}
a[tabindex="-1"][id] {
  position: relative;
}
a[tabindex="-1"][id]:after {
  content: "";
}
a.touch_link {
  color: #4b4b4b;
  display: block;
  text-decoration: none;
}
a.touch_link .title {
  color: #73a6cb;
  display: inline-block;
  margin-bottom: 0.5em;
  outline-offset: 0;
  padding: 0;
  text-decoration: underline;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a.touch_link .title.active {
  color: #4b4b4b;
}
a.touch_link:focus {
  outline: 0;
}
a.touch_link:focus .title {
  outline: 0.1875rem solid rgba(115, 166, 203, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a.touch_link:focus[data-focus-method="mouse"] .title,
a.touch_link:focus[data-focus-method="touch"] .title {
  outline: 0;
}
a.touch_link:hover .title {
  text-decoration: none;
}
.highlighted {
  -webkit-animation: highlighted_anchor 900ms;
          animation: highlighted_anchor 900ms;
}
strong {
  font-weight: 700;
}
em {
  font-style: normal;
}
blockquote {
  margin: 0 0 1em 1ch;
  text-indent: -1ch;
}
blockquote:before {
  content: "\201E";
}
blockquote:after {
  content: "\201C";
}
small {
  font-size: 85%;
}
sup {
  font-size: 85%;
  line-height: 1;
  position: relative;
  top: -0.3125rem;
}
sub {
  bottom: -0.125rem;
  font-size: 85%;
  line-height: 1;
  position: relative;
}
.table {
  -webkit-overflow-scrolling: touch;
  margin-bottom: 2em;
  overflow-y: auto;
}
* + .table {
  margin-top: 2em;
}
table {
  border-bottom: 0.0625rem solid rgba(78, 142, 189, 0.2);
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  width: 100%;
}
th {
  font-weight: 700;
  text-align: left;
}
td,
th {
  border-top: 0.0625rem solid rgba(78, 142, 189, 0.2);
  padding: 0.66666667em;
  -webkit-transition: background 300ms;
  transition: background 300ms;
  vertical-align: top;
}
tr:hover td,
tr:hover th {
  background: rgba(115, 166, 203, 0.2);
}
ol {
  counter-reset: list;
  list-style: none;
  margin: 0 0 1em;
  padding: 0;
}
ol > li {
  padding-left: 2em;
  position: relative;
}
ol > li:before {
  color: #73a6cb;
  content: counter(list) ".";
  counter-increment: list;
  font-weight: 700;
  left: 0;
  line-height: 2;
  position: absolute;
  text-align: right;
  width: 1.5em;
}
ol ol,
ol ul {
  margin: 0;
}
ul {
  list-style: url("../images/listicon.svg") !important;
  margin: 0 0 1em;
  padding-left: 1.5em;
}
ul > li {
  padding-left: 0.5em;
}
ul ol,
ul ul {
  margin: 0;
}
.defined_list dl {
  border-bottom: 0.0625rem solid rgba(78, 142, 189, 0.2);
  margin: 1em 0;
}
@media (min-width: 35rem) {
  .defined_list dl {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
}
.defined_list dt {
  border-top: 0.0625rem solid rgba(78, 142, 189, 0.2);
  font-weight: 700;
  padding: 1em 0;
}
@media (min-width: 35rem) {
  .defined_list dt {
    -webkit-box-flex: 0;
            flex: 0 0 40%;
    max-width: 40%;
    padding: 1em 1em 1em 0;
  }
}
.defined_list dd {
  margin: 0;
}
@media (min-width: 35rem) {
  .defined_list dd {
    border-top: 0.0625rem solid rgba(78, 142, 189, 0.2);
    -webkit-box-flex: 0;
            flex: 0 0 60%;
    max-width: 60%;
    padding: 1em 0.66666667em 0 0;
  }
}
nav {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
nav ol {
  margin: 0;
}
nav ul {
  list-style: none!important;
  margin: 0;
  padding: 0;
}
nav li {
  margin: 0;
  padding: 0;
}
nav li:before {
  display: none;
}
figure {
  margin: 0 0 2em;
}
figure img {
  display: block;
}
figure.center {
  margin-left: auto;
  margin-right: auto;
}
figure.floatleft {
  float: left;
  margin-right: 2em;
}
figure.floatright {
  float: right;
  margin-left: 2em;
}
figcaption {
  font-size: 85%;
  margin-top: 0.5em;
  text-align: left;
}
.img_container {
  overflow: hidden;
}
.img_container img,
.img_container svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.img_resize {
  height: 0;
  position: relative;
  width: 100%;
}
img {
  border: 0;
  display: inline-block;
  height: auto;
  max-width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
svg {
  height: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  width: 100%;
}
svg a:focus {
  outline: 0;
}
input,
select,
textarea,
button {
  font-family: inherit;
  font-weight: 400;
  letter-spacing: calc((0.875rem + 0.125
                                 * (100vw - 18.75rem)
                                 / (75))
                                 / 1000 * 0);
  font-size: 0.875rem;
}
@media (min-width: 18.75rem) {
  input,
  select,
  textarea,
  button {
    font-size: calc(0.875rem + 0.125
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media (min-width: 93.75rem) {
  input,
  select,
  textarea,
  button {
    font-size: 1rem;
  }
}
label {
  cursor: pointer;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="time"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #4b4b4b;
  box-shadow: 0 0 0 62.5rem #fff inset, 0 0 0.1875rem #73a6cb inset, 0 0 0 0.1875rem rgba(115, 166, 203, 0.2) !important;
}
input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
::-webkit-input-placeholder {
  color: #8b8b8b;
  -webkit-user-select: none;
          user-select: none;
}
:-ms-input-placeholder {
  color: #8b8b8b;
  -ms-user-select: none;
      user-select: none;
}
::placeholder {
  color: #8b8b8b;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.required_asterisk {
  color: #73a6cb;
}
.required_description {
  font-size: 85%;
}
.error_warning {
  background: #73a6cb;
  color: #fff;
  display: none;
  font-weight: 700;
  margin-right: 0.5em;
  padding: 0 0.5em;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.error_warning.show {
  display: inline-block;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.legend {
  letter-spacing: calc((1.1875rem + 0.25
                                 * (100vw - 18.75rem)
                                 / (75))
                                 / 1000 * 0);
  font-size: 1.1875rem;
  color: #4b4b4b;
  font-family: "Palanquin Dark", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 1em;
  padding: 1em 0 0;
  width: 100%;
}
@media (min-width: 18.75rem) {
  .legend {
    font-size: calc(1.1875rem + 0.25
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media (min-width: 93.75rem) {
  .legend {
    font-size: 1.4375rem;
  }
}
.legend .label {
  margin-bottom: 0;
}
.label {
  display: inline-block;
  margin-bottom: 0.5em;
}
.input,
.textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff;
  border: 0.0625rem solid rgba(78, 142, 189, 0.2);
  border-radius: 0.125rem;
  box-shadow: 0 0 0 rgba(78, 142, 189, 0.2) inset;
  color: #4b4b4b;
  margin: 0 0 1.33333333em;
  outline: 0;
  width: 100%;
}
.input[aria-invalid="true"],
.textarea[aria-invalid="true"] {
  border-color: #73a6cb;
}
.input[aria-invalid="true"]:focus,
.textarea[aria-invalid="true"]:focus {
  border-color: #73a6cb;
  box-shadow: 0 0 0.1875rem #73a6cb inset, 0 0 0 0.1875rem rgba(115, 166, 203, 0.2);
  color: #4b4b4b;
}
.input:focus,
.textarea:focus {
  border-color: #73a6cb;
  box-shadow: 0 0 0.1875rem #73a6cb inset, 0 0 0 0.1875rem rgba(115, 166, 203, 0.2);
  color: #4b4b4b;
}
.input {
  padding: 0 1em;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  height: 2.75rem;
}
@media (min-width: 18.75rem) {
  .input {
    height: calc(2.75rem + 0.625
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media (min-width: 93.75rem) {
  .input {
    height: 3.375rem;
  }
}
.textarea {
  line-height: 2;
  overflow: hidden;
  padding: 1em;
  resize: none;
  -webkit-transition: border 300ms, height 300ms;
  transition: border 300ms, height 300ms;
}
.control {
  -webkit-box-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: flex;
  margin: 0.5em 1em 1em 0;
}
.control.checkbox input:checked ~ .indicator .icon {
  opacity: 1;
  visibility: visible;
}
.control input {
  border: 0;
  cursor: pointer;
  height: 1.5rem;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 1.5rem;
  z-index: 10;
}
.control input[aria-invalid="true"] ~ .indicator {
  border-color: #73a6cb;
}
.control input[aria-invalid="true"]:focus ~ .indicator {
  border-color: #73a6cb;
  box-shadow: 0 0 0.1875rem #73a6cb inset, 0 0 0 0.1875rem rgba(115, 166, 203, 0.2);
  color: #4b4b4b;
}
.control input:focus ~ .indicator {
  border-color: #73a6cb;
  box-shadow: 0 0 0.1875rem #73a6cb inset, 0 0 0 0.1875rem rgba(115, 166, 203, 0.2);
  color: #4b4b4b;
}
.control .indicator_wrapper {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  margin-right: 1em;
  position: relative;
}
.control .indicator {
  background: #fff;
  border: 0.0625rem solid rgba(78, 142, 189, 0.2);
  border-radius: 0.125rem;
  height: 1.375rem;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 1.375rem;
}
.control .indicator .icon {
  fill: #4b4b4b;
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  visibility: hidden;
  width: 1.25rem;
}
.select_wrapper {
  display: block;
}
.select {
  background: #fff;
  border: 0.0625rem solid rgba(78, 142, 189, 0.2);
  border-radius: 0.125rem;
  box-shadow: 0 0 0.1875rem rgba(78, 142, 189, 0.2) inset;
  color: #4b4b4b;
  cursor: pointer;
  display: block;
  margin: 0 0 1.33333333em;
  padding: 0 1em;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 100%;
}
.select:before {
  border: 0.625rem solid transparent;
  border-top-color: #4b4b4b;
  content: "";
  display: inline-block;
  height: 0;
  margin-top: -0.3125rem;
  pointer-events: none;
  position: absolute;
  right: 1em;
  top: 50%;
  width: 0;
}
.select.error {
  border-color: #73a6cb;
}
.select.error.focus {
  border-color: #73a6cb;
  box-shadow: 0 0 0.1875rem #73a6cb inset, 0 0 0 0.1875rem rgba(115, 166, 203, 0.2);
  color: #4b4b4b;
}
.select.focus {
  border-color: #73a6cb;
  box-shadow: 0 0 0.1875rem #73a6cb inset, 0 0 0 0.1875rem rgba(115, 166, 203, 0.2);
  color: #4b4b4b;
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  color: #4b4b4b;
  cursor: pointer;
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  min-height: 2.625rem;
}
@media (min-width: 18.75rem) {
  select {
    min-height: calc(2.625rem + 0.625
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media (min-width: 93.75rem) {
  select {
    min-height: 3.25rem;
  }
}
select option {
  min-height: 2.625rem;
}
@media (min-width: 18.75rem) {
  select option {
    min-height: calc(2.625rem + 0.625
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media (min-width: 93.75rem) {
  select option {
    min-height: 3.25rem;
  }
}
select::-ms-expand {
  display: none;
}
select:focus:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #4b4b4b;
}
select:focus::-ms-value {
  background: none;
  color: #4b4b4b;
}
@-webkit-keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes icon_move {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-1.25rem);
            transform: translateY(-1.25rem);
  }
  50% {
    opacity: 0.6;
    -webkit-transform: translateY(1.875rem);
            transform: translateY(1.875rem);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-1.25rem);
            transform: translateY(-1.25rem);
  }
}
@keyframes icon_move {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-1.25rem);
            transform: translateY(-1.25rem);
  }
  50% {
    opacity: 0.6;
    -webkit-transform: translateY(1.875rem);
            transform: translateY(1.875rem);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-1.25rem);
            transform: translateY(-1.25rem);
  }
}
@-webkit-keyframes leaves {
  0% {
    display: block;
    opacity: 0.5;
    -webkit-transform: translate(0, 0rem) rotateZ(0deg) scale(0.5);
            transform: translate(0, 0rem) rotateZ(0deg) scale(0.5);
    -webkit-transition: scale 5s;
    transition: scale 5s;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(5rem, -4.375rem) rotateZ(180deg) scale(0.5);
            transform: translate(5rem, -4.375rem) rotateZ(180deg) scale(0.5);
  }
}
@keyframes leaves {
  0% {
    display: block;
    opacity: 0.5;
    -webkit-transform: translate(0, 0rem) rotateZ(0deg) scale(0.5);
            transform: translate(0, 0rem) rotateZ(0deg) scale(0.5);
    -webkit-transition: scale 5s;
    transition: scale 5s;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(5rem, -4.375rem) rotateZ(180deg) scale(0.5);
            transform: translate(5rem, -4.375rem) rotateZ(180deg) scale(0.5);
  }
}
@-webkit-keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(115, 166, 203, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(115, 166, 203, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(115, 166, 203, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(115, 166, 203, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@-webkit-keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@media screen {
  .visible_for_screen_reader {
    border: 0;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  abbr {
    text-decoration: none;
  }
  [role="tablist"] {
    margin-bottom: 2em;
  }
  * + [role="tablist"] {
    margin-top: 2em;
  }
  [role="tab"] {
    -webkit-box-align: center;
            align-items: center;
    border-top: 0.0625rem solid rgba(78, 142, 189, 0.2);
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 100%;
    margin: 0 0 0.5em;
    min-width: 100%;
    padding: 0.66666667em 0;
  }
  [role="tab"] .title {
    flex-basis: 100%;
    margin-right: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  [role="tab"] .icon {
    display: block;
    fill: #73a6cb;
    height: 1.5rem;
    width: 1.5rem;
  }
  [role="tab"][aria-expanded="true"] {
    border-color: #73a6cb;
  }
  [role="tab"][aria-expanded="true"] .icon {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  [role="tab"]:focus {
    border-color: #73a6cb;
    outline: 0;
  }
  [role="tab"]:focus .title {
    color: #73a6cb;
  }
  [role="tabpanel"] {
    height: auto;
    margin: 0;
    overflow: hidden;
  }
  [role="tabpanel"][aria-hidden="false"] {
    margin: 2em 0;
  }
  .csstransitions [role="tabpanel"].animated {
    -webkit-transition: margin 600ms, max-height 600ms;
    transition: margin 600ms, max-height 600ms;
  }
  .contact_wrapper {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .contact_wrapper p {
    margin-right: 3em;
  }
  .contact_wrapper .postal_address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact strong {
    min-width: 5.3125rem;
  }
  .address_vcard_button {
    margin: 1em 0;
  }
  .address_call_button {
    margin: 1em 0;
  }
  aside {
    margin-top: 4em;
  }
  .atikon_logo {
    height: 100%;
  }
  .author_ver {
    display: inline-block;
    fill: #4b4b4b;
    height: 4.6875rem;
    line-height: 4.6875rem;
    margin: 0.9375rem;
    opacity: 0.4;
    position: absolute;
    right: 0.33333333em;
    width: 0.5625rem;
  }
  .breadcrumbs {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1em;
  }
  .breadcrumbs p {
    margin: 0 0.5em 0 0;
  }
  .breadcrumbs ol {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .breadcrumbs .separator {
    margin: 0 0.5em;
  }
  .button {
    border-radius: 5rem;
    font-family: "Palanquin Dark", sans-serif;
    margin-bottom: 1em;
    padding: 0 2em 0.25em 2em;
    text-align: center;
    text-transform: uppercase;
    -webkit-box-align: center;
            align-items: center;
    background: #73a6cb;
    border: 0.0625rem solid #73a6cb;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    min-height: 2.625rem;
  }
  .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    line-height: 1;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button .text {
    font-weight: 400;
    line-height: 1;
    padding: 0.5em 0;
  }
  .button .icon + .text {
    padding-left: 0.66666667em;
  }
  .button.white {
    background-color: #fff;
    color: #73a6cb;
  }
  .button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(115, 166, 203, 0.2);
    outline: 0;
    background: #98bed9;
    border-color: #98bed9;
    color: #fff;
  }
  .button:focus .icon {
    fill: #fff;
  }
  .button:hover {
    background: #98bed9;
    border-color: #98bed9;
    color: #fff;
  }
  .button:hover .icon {
    fill: #fff;
  }
  .button:active,
  .button.active {
    box-shadow: 0 0 0.1875rem #73a6cb inset;
    -webkit-box-align: center;
            align-items: center;
    background: #73a6cb;
    border: 0.0625rem solid #73a6cb;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button:active > span,
  .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .button:active .icon,
  .button.active .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    line-height: 1;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button:active .text,
  .button.active .text {
    font-weight: 400;
    line-height: 1;
    padding: 0.5em 0;
  }
  .button:active .icon + .text,
  .button.active .icon + .text {
    padding-left: 0.66666667em;
  }
  .bypass_blocks {
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    z-index: 6000;
  }
  .bypass_blocks li {
    display: block;
    width: 18.75rem;
  }
  .bypass_blocks a {
    background: #fff;
    color: #73a6cb;
    display: inline-block;
    font-size: 85%;
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0.5em;
    position: absolute;
    text-decoration: underline;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .bypass_blocks a:focus {
    color: #73a6cb;
    height: auto;
    left: 0.5em;
    top: 0.5em;
    width: auto;
  }
  .chart_organigram {
    display: none;
  }
  .google-visualization-orgchart-table {
    border-bottom: 0;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .google-visualization-orgchart-table .node {
    background: rgba(115, 166, 203, 0.2);
    border: 0;
    box-shadow: none;
    cursor: pointer;
    font-size: 85%;
    padding: 0.4375rem;
    vertical-align: middle;
  }
  .google-visualization-orgchart-table .google-visualization-orgchart-linenode {
    border-color: rgba(78, 142, 189, 0.2);
    border-top: 0;
  }
  .company_logo {
    max-width: 11.25rem;
    background: #fff;
    display: block;
    padding: 1em 1.33333333em;
  }
  .download_overview {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: colum;
    flex-wrap: wrap;
    position: relative;
  }
  .download_overview .overview_title {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-bottom: 2em;
    width: 100%;
  }
  .download_overview .overview_title .icon {
    display: block;
    fill: #4b4b4b;
    height: 4.125rem;
    position: relative;
    text-align: center;
    width: 4.125rem;
    z-index: 2;
  }
  .download_overview .overview_title .icon:after {
    background-image: url("../images/leaf_main.svg");
    background-position: center center;
    background-size: cover;
    content: "";
    display: block;
    height: 6.875rem;
    left: -2.8125rem;
    opacity: 0.5;
    position: absolute;
    top: -2.1875rem;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    width: 6.875rem;
    z-index: -1;
  }
  .download_overview .overview_title:hover .icon::after {
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-name: leaves;
            animation-name: leaves;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    opacity: 0;
    position: absolute;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    z-index: -1;
  }
  .download_overview .touch_link {
    display: -webkit-box;
    display: flex;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0 2em;
  }
  .download_overview .touch_link .title_wrapper {
    margin-bottom: 0.5em;
    padding-top: 0;
  }
  .download_overview .touch_link .title {
    color: #4b4b4b;
    margin-bottom: 0.5em;
    text-decoration: none;
  }
  .download_overview .touch_link:hover .title {
    text-decoration: underline;
  }
  .download_overview .icon {
    fill: #73a6cb;
    flex-shrink: 0;
    height: 1.5rem;
    margin-right: 1em;
    width: 1.5rem;
  }
  .dropdown_menu {
    display: none;
  }
  .dropdown_menu > ul {
    display: -webkit-box;
    display: flex;
    justify-content: space-around;
    position: relative;
    width: 100%;
  }
  .dropdown_menu > ul li {
    position: relative;
  }
  .dropdown_menu > ul li:last-child ul {
    right: 0;
  }
  .dropdown_menu > ul li:hover > a,
  .dropdown_menu > ul li.hover > a {
    color: #2d5978;
  }
  .dropdown_menu > ul li:hover > a .icon,
  .dropdown_menu > ul li.hover > a .icon {
    opacity: 1;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
  }
  .dropdown_menu > ul li:hover > ul,
  .dropdown_menu > ul li.hover > ul {
    visibility: visible;
  }
  .dropdown_menu > ul li .icon {
    box-sizing: content-box;
    display: inline-block;
    height: 0.625rem;
    padding-left: 1em;
    width: 0.625rem;
  }
  .dropdown_menu > ul > li {
    -webkit-box-align: center;
            align-items: center;
  }
  .dropdown_menu > ul > li > a {
    -webkit-box-align: center;
            align-items: center;
    color: #73a6cb;
    display: -webkit-box;
    display: flex;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    padding: 1em 0.33333333em;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    z-index: 1;
  }
  .dropdown_menu > ul > li > a:after {
    background-image: url("../images/leaf_main.svg");
    background-position: center center;
    background-size: cover;
    content: "";
    display: block;
    height: 5.625rem;
    left: -1.5625rem;
    opacity: 0;
    position: absolute;
    top: -0.9375rem;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    width: 5.625rem;
    z-index: -1;
  }
  .dropdown_menu > ul > li > a .icon {
    bottom: 0rem;
    fill: #2d5978;
    height: 1.125rem;
    left: 0;
    margin: 0 auto;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    width: 1.125rem;
  }
  .dropdown_menu > ul > li > a.active {
    color: #2d5978;
  }
  .dropdown_menu > ul > li > a.active:after {
    opacity: 0.5;
  }
  .dropdown_menu > ul > li ul {
    background: #fff;
    padding: 1em 0;
    position: absolute;
    top: 5em;
    visibility: hidden;
    z-index: 1000;
  }
  .dropdown_menu > ul > li ul:target {
    visibility: visible;
  }
  .dropdown_menu > ul > li ul a {
    color: #4b4b4b;
    display: -webkit-box;
    display: flex;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    -webkit-box-pack: justify;
            justify-content: space-between;
    padding: 0 1em;
    text-decoration: none;
    text-shadow: inherit;
    white-space: nowrap;
    width: 100%;
  }
  .dropdown_menu > ul > li ul a:hover {
    color: #73a6cb;
    text-shadow: inherit;
  }
  .dropdown_menu > ul > li ul a:focus {
    outline-offset: -0.125rem;
  }
  .dropdown_menu > ul > li ul a.active {
    color: #73a6cb;
  }
  .dropdown_menu > ul > li ul ul {
    left: 100%;
    position: absolute;
    top: -1em;
  }
  footer {
    margin-top: 3em;
    overflow: hidden;
  }
  footer .overlay_startpage {
    background-color: #ffffff;
    display: block;
    left: -1%;
    margin-bottom: -0.125rem;
    max-width: 120%;
    position: relative;
    width: 120%;
    z-index: -2;
  }
  footer .overlay + .overlay_startpage {
    background-color: rgba(115, 166, 203, 0.2);
    display: block;
    left: -1%;
    margin-bottom: -0.125rem;
    max-width: 120%;
    position: relative;
    width: 120%;
    z-index: -2;
  }
  @supports (padding-left: env(safe-area-inset-bottom)) {
    html.touch footer {
      padding-bottom: env(safe-area-inset-bottom);
    }
  }
  .main_footer {
    background-color: #73a6cb;
    overflow: hidden;
    padding-bottom: 4em;
    position: relative;
  }
  .main_footer h3 {
    text-transform: uppercase;
  }
  .main_footer p,
  .main_footer a,
  .main_footer h3 {
    color: #000000;
    font-size: 90%;
  }
  .main_footer a {
    text-decoration: none;
  }
  .main_footer a:hover {
    text-decoration: underline;
  }
  .main_footer .address {
    margin-bottom: 1.25rem;
  }
  .main_footer .address .address_p {
    margin: 0;
  }
  .main_footer .address .contact_text {
    display: none;
  }
  .main_footer .address .icon {
    display: block;
    fill: #000000;
    height: 1rem;
    margin-right: 0.5em;
    width: 1rem;
  }
  .main_footer .related_links {
    -webkit-box-align: end;
            align-items: flex-end;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    -webkit-box-pack: start;
            justify-content: flex-start;
    margin-top: 1em;
  }
  .main_footer .related_links ul {
    -webkit-box-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .main_footer .related_links ul li {
    margin-right: 1em;
  }
  .main_footer .related_links ul li:last-child {
    margin-right: 0;
  }
  .form_disclaimer {
    background: rgba(115, 166, 203, 0.2);
    border-radius: 0.125rem;
    color: #4b4b4b;
    margin-bottom: 1em;
    padding: 1.33333333em 2em;
  }
  .file {
    display: -webkit-box;
    display: flex;
    flex-basis: 65%;
    margin: 0 0 1.33333333em;
    position: relative;
    height: 2.75rem;
  }
  .file.error .file_name {
    border-color: #73a6cb;
  }
  .file .file_name {
    background: #fff;
    border: 0.0625rem solid rgba(78, 142, 189, 0.2);
    border-radius: 0.125rem 0 0 0.125rem;
    box-shadow: 0 0 0.1875rem rgba(78, 142, 189, 0.2) inset;
    color: #4b4b4b;
    -webkit-box-flex: 0;
            flex: 0 1 100%;
    overflow: hidden;
    padding: 0 1em;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 8.125rem;
    line-height: 2.625rem;
    height: 2.75rem;
  }
  .file .file_button {
    border-radius: 0 0.125rem 0.125rem 0;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    padding: 0 2em;
    z-index: 10;
    -webkit-box-align: center;
            align-items: center;
    background: #73a6cb;
    border: 0.0625rem solid #73a6cb;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .file .file_button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    line-height: 1;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .file .file_button .text {
    font-weight: 400;
    line-height: 1;
    padding: 0.5em 0;
  }
  .file .file_button .icon + .text {
    padding-left: 0.66666667em;
  }
  .file .file_button.focus {
    box-shadow: 0 0 0 0.1875rem rgba(115, 166, 203, 0.2);
    background: #98bed9;
    border-color: #98bed9;
    color: #fff;
  }
  .file .file_button.focus .icon {
    fill: #fff;
  }
  .file [type="file"] {
    bottom: 0;
    color: transparent;
    cursor: pointer;
    display: block;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 20;
    height: 2.75rem;
  }
  .file [type="file"]:hover ~ .file_button {
    background: #98bed9;
    border-color: #98bed9;
    color: #fff;
  }
  .file [type="file"]:hover ~ .file_button .icon {
    fill: #fff;
  }
  .file [type="file"]:active ~ .file_button {
    box-shadow: 0 0 0.1875rem #73a6cb inset;
    -webkit-box-align: center;
            align-items: center;
    background: #73a6cb;
    border: 0.0625rem solid #73a6cb;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file [type="file"]:active ~ .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .file [type="file"]:active ~ .file_button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    line-height: 1;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .file [type="file"]:active ~ .file_button .text {
    font-weight: 400;
    line-height: 1;
    padding: 0.5em 0;
  }
  .file [type="file"]:active ~ .file_button .icon + .text {
    padding-left: 0.66666667em;
  }
  button {
    cursor: pointer;
    margin: 1em 0;
    outline: 0;
    overflow: hidden;
    padding: 0 2em;
    width: 100%;
    height: 2.75rem;
  }
  button::-moz-focus-inner {
    border: 0;
  }
  .google_maps {
    margin: 0 0 1em;
  }
  .google_maps .map {
    background: rgba(115, 166, 203, 0.2);
    height: 50vh;
    min-height: 25rem;
  }
  .google_maps .map img {
    max-width: none;
  }
  .google_maps .map .gm-style > button {
    width: auto;
  }
  .google_maps .map_print {
    display: none;
  }
  .google_maps .travel_mode {
    display: -webkit-box;
    display: flex;
    margin: 0 0 1em;
  }
  .google_maps .travel_mode input {
    opacity: 0;
    position: absolute;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio {
    z-index: 5;
    background: #73a6cb;
    border-color: #73a6cb;
    color: #fff;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio .icon {
    fill: #fff;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio {
    box-shadow: 0 0 0 0.1875rem rgba(115, 166, 203, 0.2);
    z-index: 15;
    background: #98bed9;
    border-color: #98bed9;
    color: #fff;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio .icon {
    fill: #fff;
  }
  .google_maps .travel_mode_entry {
    flex-basis: 100%;
    height: 2.75rem;
  }
  .google_maps .travel_mode_radio {
    cursor: pointer;
    position: relative;
    width: 100%;
    -webkit-box-align: center;
            align-items: center;
    background: #fff;
    border: 0.0625rem solid rgba(78, 142, 189, 0.2);
    color: #4b4b4b;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .google_maps .travel_mode_radio > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #4b4b4b;
    flex-shrink: 0;
    height: 1.5rem;
    line-height: 1;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .google_maps .travel_mode_radio .text {
    font-weight: 400;
    line-height: 1;
    padding: 0.5em 0;
  }
  .google_maps .travel_mode_radio .icon + .text {
    padding-left: 0.66666667em;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #4b4b4b;
    min-width: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
  }
  .google_maps .travel_mode_radio.driving {
    border-radius: 0.125rem 0 0 0.125rem;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.transit,
  .google_maps .travel_mode_radio.bicycling {
    border-left-width: 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.walking {
    border-left-width: 0;
    border-radius: 0 0.125rem 0.125rem 0;
  }
  .google_maps .warnings {
    background: #f6ecec;
    border-radius: 0.125rem;
    color: #73a6cb;
    font-size: 85%;
    margin-bottom: 1em;
    padding: 1.33333333em 2em;
  }
  .google_maps .direction {
    margin: 1em 0;
  }
  .google_maps .button_wrapper {
    margin-top: auto;
  }
  .google_maps .panel_wrapper {
    padding-top: 1em;
  }
  .google_maps .steps {
    min-width: 100%;
  }
  .google_maps .step {
    border-top: 0.0625rem solid rgba(78, 142, 189, 0.2);
    display: -webkit-box;
    display: flex;
  }
  .google_maps .step.last {
    border-bottom: 0.0625rem solid rgba(78, 142, 189, 0.2);
  }
  .google_maps .step:before {
    font-size: 85%;
    padding: 1em 0;
  }
  .google_maps .instructions,
  .google_maps .distance {
    font-size: 85%;
    padding: 1em 0;
  }
  .google_maps .instructions {
    overflow: hidden;
    padding-left: 1em;
    width: 100%;
    word-wrap: break-word;
  }
  .google_maps .distance {
    min-width: 5rem;
    text-align: right;
  }
  .google_maps .infowindow {
    max-width: 13.75rem;
  }
  .container {
    margin: 0 auto;
    max-width: 100rem;
    min-width: 18.75rem;
    padding: 0 0.9375rem;
  }
  @supports (padding-left: env(safe-area-inset-left)) {
    html.mobile .container {
      padding-left: max(0.9375rem, env(safe-area-inset-left));
      padding-right: max(0.9375rem, env(safe-area-inset-right));
    }
  }
  .container_small {
    margin: 0 auto;
    min-width: 18.75rem;
    padding: 0 0.9375rem;
  }
  @supports (padding-left: env(safe-area-inset-left)) {
    html.mobile .container_small {
      padding-left: max(0.9375rem, env(safe-area-inset-left));
      padding-right: max(0.9375rem, env(safe-area-inset-right));
    }
  }
  .row {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    margin-left: -0.46875rem;
    margin-right: -0.46875rem;
  }
  .row.around_xsmall {
    justify-content: space-around;
  }
  .row.between_xsmall {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xsmall {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xsmall {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xsmall {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xsmall {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xsmall {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xsmall {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xsmall {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
  .row.no_column_margin {
    margin-left: 0;
    margin-right: 0;
  }
  .row.no_column_margin > [class*="span"] {
    padding-left: 0;
    padding-right: 0;
  }
  .row.half_column_margin {
    margin-left: -0.234375rem;
    margin-right: -0.234375rem;
  }
  .row.half_column_margin > [class*="span"] {
    padding-left: 0.234375rem;
    padding-right: 0.234375rem;
  }
  [class*="span"] {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
  }
  [class*="span"].span_column {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .span1_xsmall {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xsmall {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xsmall {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xsmall {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xsmall {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xsmall {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xsmall {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xsmall {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xsmall {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xsmall {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xsmall {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xsmall {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xsmall {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xsmall {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xsmall {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xsmall {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xsmall {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xsmall {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xsmall {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xsmall {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xsmall {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xsmall {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xsmall {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xsmall {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  .startpage .header_background {
    padding-bottom: 55% !important;
  }
  .header_background {
    background-position: center center;
    background-size: cover;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0 auto;
    overflow: hidden;
    padding-bottom: 80%;
  }
  .header_background .icon {
    bottom: 15%;
    fill: #fff;
    height: 1.375rem;
    margin: 0 auto;
    position: absolute;
    width: 1.375rem;
  }
  .header_background .icon_animated {
    -webkit-animation: icon_move 1200ms;
            animation: icon_move 1200ms;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    opacity: 0.4;
    -webkit-transform: translateY(0rem);
            transform: translateY(0rem);
  }
  .header_background .overlay {
    bottom: -0.3125rem;
    left: -10%;
    overflow: hidden;
    position: absolute;
    right: 0;
    width: 120%;
  }
  .header_background .overlay img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }
  .header_background:after {
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
    content: "";
    height: 1.25rem;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
  .main_header {
    background: #fff;
    display: block;
    left: 0;
    position: relative;
    right: 0;
    z-index: 9;
  }
  .main_navigation {
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .u_online_secure_login {
    margin-bottom: 2em;
  }
  .img_container.u_online_svg_2_small {
    max-width: 18.125rem;
  }
  .img_container.u_online_svg_2_small .svg_resize {
    padding-bottom: 634.48275862%;
  }
  .img_container.u_online_svg_2 {
    margin: 0 0 2em;
    max-width: 28.125rem;
  }
  .img_container.u_online_svg_2 .svg_resize {
    padding-bottom: 148.88888889%;
  }
  .u_online_svg_2_small {
    display: block;
    margin: 2em auto;
  }
  .u_online_svg_2 {
    display: none;
  }
  .u_online_2_headline {
    fill: #4b4b4b;
    font-family: "Merriweather", sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .u_online_2_headline .strong {
    fill: #4b4b4b;
    font-weight: 700;
  }
  .u_online_text {
    fill: #4b4b4b;
    font-family: "Merriweather", sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .u_online_company_logo {
    fill: #4b4b4b;
  }
  .u_online_warning_icon {
    fill: #4b4b4b;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_euro_icon,
  .u_online_check_icon {
    fill: #4b4b4b;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_icon {
    fill: #73a6cb;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_button .normal {
    display: block;
  }
  .u_online_button .hover {
    display: none;
  }
  .u_online_button:hover .normal {
    display: none;
  }
  .u_online_button:hover .hover {
    display: block;
  }
  .u_online_button:hover .u_online_icon {
    fill: #73a6cb;
  }
  .u_online_button:hover .u_online_euro_icon,
  .u_online_button:hover .u_online_warning_icon,
  .u_online_button:hover .u_online_check_icon {
    fill: #73a6cb;
  }
  .u_online_button:hover .u_online_icon {
    fill: #4b4b4b;
  }
  .u_online_lines {
    fill: #73a6cb;
  }
  .u_online_benefits_flow h2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 30rem;
    text-align: center;
  }
  .u_online_benefits_flow ul {
    list-style: none!important;
  }
  .u_online_benefits_flow .table_cell.text {
    margin: 0 auto;
    max-width: 18.75rem;
  }
  .u_online_benefits_flow .arrow_large {
    display: none;
  }
  .u_online_benefits_flow .arrow_small {
    display: block;
    height: 4rem;
    margin: 0 auto;
    width: 1.875rem;
  }
  .u_online_benefits_flow .flow_text {
    display: none;
  }
  .u_online_benefits_flow .u_online_arrow_main_color {
    fill: #73a6cb;
  }
  .u_online_benefits_flow .u_online_arrow_main_color_medium {
    fill: #b9d3e5;
  }
  .u_online_benefits_flow .header {
    display: table;
    margin: 1em auto;
    max-width: 18.75rem;
    text-align: right;
  }
  .u_online_benefits_flow .header .text {
    display: table-cell;
    margin: 0;
    padding: 0 1em 0 0;
    vertical-align: middle;
  }
  .u_online_benefits_flow .header .arrow_small {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .u_online_benefits_flow .footer {
    display: table;
    margin: 1em auto;
    max-width: 18.75rem;
  }
  .u_online_benefits_flow .footer .text {
    display: table-cell;
    margin: 0;
    padding: 0 0 0 1em;
    vertical-align: middle;
  }
  .u_online_benefits_flow .description {
    margin: 0 auto;
  }
  .u_online_benefits_flow .description ul {
    list-style: none;
    margin: 2em;
  }
  .u_online_benefits_flow .description li {
    margin: 1em 0;
    padding-left: 2.125rem;
    position: relative;
  }
  .u_online_benefits_flow .description li:before {
    background-color: #73a6cb;
    background-image: url("../images/u_online/u_online_check.svg");
    content: "";
    display: block;
    height: 1.5rem;
    left: 0;
    position: absolute;
    width: 1.5rem;
  }
  .u_online_benefits_flow .lock {
    background: rgba(115, 166, 203, 0.2);
    display: table;
    margin: 0 auto 1em;
    padding: 2em;
  }
  .u_online_benefits_flow .lock .text {
    display: table-cell;
    padding-left: 2em;
    vertical-align: middle;
  }
  .u_online_benefits_flow .lock:before {
    background: url("../images/u_online/u_online_lock.svg") no-repeat 50% 50%;
    content: "";
    display: table-cell;
    height: 1.75rem;
    width: 1.75rem;
  }
  .u_online_benefits_flow .benefits_button {
    border: 0;
    cursor: pointer;
    display: table;
    margin: 0 auto;
    min-width: 14.375rem;
    text-decoration: none;
  }
  .u_online_benefits_flow .benefits_button > span {
    display: table-cell;
  }
  .u_online_benefits_flow .benefits_button .text {
    border-bottom-width: 0.125rem;
    border-color: #73a6cb;
    border-left-width: 0.0625rem;
    border-radius: 0.125rem 0 0 0.125rem;
    border-style: solid;
    border-top-width: 0.0625rem;
    color: #4b4b4b;
    display: block;
    font-size: 130%;
    font-weight: 500;
    margin-right: 0.1875rem;
    padding: 0 2em;
    text-align: center;
    text-transform: uppercase;
    -webkit-transition: border 300ms;
    transition: border 300ms;
    line-height: 2.75rem;
  }
  .u_online_benefits_flow .benefits_button:after {
    background-color: #73a6cb;
    background-image: url("../images/u_online/u_online_button_arrow.svg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 60%;
    border-radius: 0 0.125rem 0.125rem 0;
    content: "";
    display: table-cell;
    position: relative;
    -webkit-transition: background-color 300ms;
    transition: background-color 300ms;
    vertical-align: middle;
    z-index: 1;
    height: 2.75rem;
    width: 2.75rem;
  }
  .u_online_benefits_flow .benefits_button.visible .text {
    border-color: #4b4b4b;
  }
  .u_online_benefits_flow .benefits_button.visible:after {
    background-color: #4b4b4b;
    border-radius: 0.125rem 0 0 0.125rem;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .u_online_benefits_flow .benefits_button:hover .text {
    border-color: #4e8ebd;
  }
  .u_online_benefits_flow .benefits_button:hover:after {
    background-color: #4e8ebd;
  }
  .u_online_benefits_flow .flow_image {
    border: 0.25rem solid #73a6cb;
    border-radius: 50%;
    box-sizing: content-box;
    height: 15.625rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 15.625rem;
  }
  .u_online_benefits_flow .flow_image .text {
    background: rgba(115, 166, 203, 0.85);
    bottom: 0;
    color: #fff;
    left: 0;
    padding: 1em 0;
    position: absolute;
    text-align: center;
    width: 100%;
  }
  .u_online_benefits_flow .flow_image img {
    border-radius: 50%;
  }
  .infolist_anchor_overview {
    background: rgba(115, 166, 203, 0.2);
    border-radius: 0.125rem;
    padding: 1em 2em;
  }
  .infolist_anchor_overview a {
    display: block;
    padding: 1em 0;
  }
  .infolist_anchor_overview li {
    border-top: 0.0625rem solid rgba(78, 142, 189, 0.2);
  }
  .infolist_anchor_overview li:first-child {
    border-top: 0;
  }
  .infolist_overview {
    background: rgba(115, 166, 203, 0.2);
    padding: 4em 2em 2em 2em;
    position: relative;
  }
  .infolist_overview .overview_title {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-bottom: 2em;
  }
  .infolist_overview .overview_title .icon {
    display: block;
    fill: #4b4b4b;
    height: 4.125rem;
    position: relative;
    text-align: center;
    width: 4.125rem;
    z-index: 2;
  }
  .infolist_overview .overview_title .icon:after {
    background-image: url("../images/leaf_main.svg");
    background-position: center center;
    background-size: cover;
    content: "";
    display: block;
    height: 7.5rem;
    left: -2.8125rem;
    opacity: 0.5;
    position: absolute;
    top: -2.1875rem;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    width: 7.5rem;
    z-index: -1;
  }
  .infolist_overview .overview_title:hover .icon::after {
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-name: leaves;
            animation-name: leaves;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    opacity: 0;
    position: absolute;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    z-index: -1;
  }
  .infolist_overview .touch_link .title {
    color: #4b4b4b;
    margin-bottom: 0.5em;
    text-decoration: none;
  }
  .infolist_overview .touch_link:hover .title {
    text-decoration: underline;
  }
  .amount {
    display: block;
    text-align: right;
    white-space: nowrap;
  }
  .steuerlexikon .amount {
    display: table-cell;
  }
  .multi_columns li {
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
  }
  .infolist_top_link {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin: 1em 0;
  }
  .infolist_top_link a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    text-align: center;
  }
  .infolist_top_link .icon {
    display: block;
    fill: #73a6cb;
    height: 3rem;
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
    width: 3rem;
  }
  .disclaimer {
    background: rgba(115, 166, 203, 0.2);
    border-radius: 0.125rem;
    color: #4b4b4b;
    font-size: 85%;
    margin-bottom: 1em;
    padding: 1.33333333em 2em;
  }
  .infolist_download {
    -webkit-box-align: center;
            align-items: center;
    background: rgba(115, 166, 203, 0.2);
    border-radius: 0.125rem;
    color: #4b4b4b;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-bottom: 2em;
    padding: 2em;
  }
  .infolist_download .infolist_title {
    font-weight: 700;
  }
  .infolist_download .button {
    margin-bottom: 0;
  }
  .infolist_download p {
    margin-bottom: 0;
    margin-right: 2em;
  }
  .checklist_entry {
    border-top: 0.0625rem solid rgba(78, 142, 189, 0.2);
    margin-bottom: 1em;
    padding-top: 1em;
  }
  .checklist_entry:first-child {
    border-top: 0;
    padding-top: 0;
  }
  .kanzlei_im_netz {
    margin: 0 0 2em;
  }
  .kanzlei_im_netz a {
    padding: 2em;
  }
  .kanzlei_im_netz a:after {
    clear: both;
  }
  .kanzlei_im_netz a:after,
  .kanzlei_im_netz a:before {
    content: "";
    display: table;
  }
  .kanzlei_im_netz a:hover {
    background: rgba(75, 75, 75, 0.1);
    -webkit-transition: background 300ms;
    transition: background 300ms;
  }
  .kanzlei_im_netz .icon {
    border-radius: 0.125rem;
    float: left;
    height: 2.75rem;
    margin: 1em 2em 0 0;
    width: 2.75rem;
  }
  .kanzlei_im_netz .icon_mobile,
  .kanzlei_im_netz .icon_mail,
  .kanzlei_im_netz .icon_vimeo {
    background: #73a6cb;
    fill: #fff;
    padding: 0.3125rem;
  }
  .kanzlei_im_netz .icon_mobile svg,
  .kanzlei_im_netz .icon_mail svg,
  .kanzlei_im_netz .icon_vimeo svg {
    height: 2.125rem;
    width: 2.125rem;
  }
  .kanzlei_im_netz .icon_vimeo {
    background: #73a6cb;
    fill: #fff;
  }
  .kanzlei_im_netz .description {
    display: block;
    overflow: hidden;
  }
  .intro {
    text-align: center;
    padding-bottom: 50px;
  }
  .intro h1 {
    position: relative;
  }
  .intro h1:after {
    background-color: #73a6cb;
    bottom: -0.25em;
    content: "";
    height: 0.25rem;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 12.5rem;
  }
  .news_section {
    background: rgba(115, 166, 203, 0.2);
    padding: 4em 0 4em 0;
  }
  .cta {
    background: #73a6cb;
    color: #fff;
    padding: 6em 0 8em 0;
    position: relative;
    text-align: center;
    z-index: 0;
  }
  .cta .container {
    position: relative;
    z-index: 2;
  }
  .cta:before {
    background-image: url("../images/logo_background.svg");
    background-position: 10% center;
    background-repeat: no-repeat;
    background-size: 30%;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .cta h2 {
    color: #fff;
  }
  .cta.uonline {
    margin-top: 2rem;
    padding: 2rem;
  }
  .cta.uonline .u_online_secure_login {
    margin-bottom: 0;
  }
  .cta.uonline .u_online_secure_login a.button {
    background-color: #fff;
    color: #73a6cb;
  }
  .cta.uonline .u_online_secure_login a.button svg {
    fill: #73a6cb;
  }
  .cta.uonline .u_online_secure_login a.button:hover {
    background-color: #98bed9;
    color: #fff;
  }
  .cta.uonline .u_online_secure_login a.button:hover svg {
    fill: #fff;
  }
  .startpage_onlinetools {
    margin-top: 6em;
  }
  .startpage_onlinetool_overview_wrapper {
    margin-top: 2em;
    position: relative;
    z-index: 1;
  }
  .startpage_onlinetool_overview_wrapper .onlinetool {
    background-size: cover !important;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    margin-bottom: 1.33333333em;
    min-height: 18.75rem;
    text-decoration: none;
  }
  .startpage_onlinetool_overview_wrapper .onlinetool:hover .title {
    background: #73a6cb;
    color: #fff;
    -webkit-transition: background 300ms, color 300ms;
    transition: background 300ms, color 300ms;
  }
  .startpage_onlinetool_overview_wrapper .onlinetool .title {
    align-self: flex-end;
    background: rgba(255, 255, 255, 0.8);
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin: 0;
    padding: 1.33333333em;
    text-transform: uppercase;
    -webkit-transition: background 300ms, color 300ms;
    transition: background 300ms, color 300ms;
    width: 100%;
  }
  .sidebar_links {
    margin-top: 2em;
  }
  .useful_link_overview {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: colum;
    flex-wrap: wrap;
    position: relative;
  }
  .useful_link_overview .overview_title {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-bottom: 2em;
    width: 100%;
  }
  .useful_link_overview .overview_title .icon {
    display: block;
    fill: #4b4b4b;
    height: 4.125rem;
    position: relative;
    text-align: center;
    width: 4.125rem;
    z-index: 2;
  }
  .useful_link_overview .overview_title .icon:after {
    background-image: url("../images/leaf_main.svg");
    background-position: center center;
    background-size: cover;
    content: "";
    display: block;
    height: 7.5rem;
    left: -2.8125rem;
    opacity: 0.5;
    position: absolute;
    top: -2.1875rem;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    width: 7.5rem;
    z-index: -1;
  }
  .useful_link_overview .overview_title:hover .icon::after {
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-name: leaves;
            animation-name: leaves;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    opacity: 0;
    position: absolute;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    z-index: -1;
  }
  .useful_link_overview .touch_link {
    display: -webkit-box;
    display: flex;
    flex-basis: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    max-width: 100%;
    padding: 0 2em;
  }
  .useful_link_overview .touch_link .title {
    color: #4b4b4b;
    margin-bottom: 0.5em;
    text-decoration: none;
    width: 100%;
  }
  .useful_link_overview .touch_link:hover .title {
    text-decoration: underline;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: block!important;
    height: 0;
    margin-bottom: 0;
    overflow: hidden;
    -webkit-transition: height 600ms;
    transition: height 600ms;
  }
  .toggle_offcanvas_menu {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    position: relative;
  }
  .toggle_offcanvas_menu label {
    -webkit-box-align: center;
            align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 112.5%;
    font-weight: 700;
    height: 2.75rem;
    position: relative;
    text-transform: uppercase;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 10;
  }
  .toggle_offcanvas_menu label .icon {
    fill: #73a6cb;
    height: 1.875rem;
    margin-right: 1em;
    position: relative;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: -webkit-transform 350ms 150ms;
    transition: -webkit-transform 350ms 150ms;
    transition: transform 350ms 150ms;
    transition: transform 350ms 150ms, -webkit-transform 350ms 150ms;
    width: 1.625rem;
  }
  .toggle_offcanvas_menu label .text {
    display: none;
  }
  .toggle_offcanvas_menu a {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .toggle_offcanvas_menu .open_offcanvas_menu {
    display: block;
  }
  .toggle_offcanvas_menu .close_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(75, 75, 75, 0.5);
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 240ms, visibility 240ms;
    transition: opacity 240ms, visibility 240ms;
    visibility: hidden;
    z-index: 110;
  }
  .offcanvas_menu {
    background-color: #73a6cb;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.2);
    display: block;
    min-height: 100%;
    opacity: 0;
    padding: 2em 0;
    position: absolute;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms, -webkit-transform 240ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    width: 15.625rem;
    z-index: 5000;
    right: 0;
    -webkit-transform-origin: right;
            transform-origin: right;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li {
    padding: 0 2em;
    position: relative;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    color: #fff;
    display: block;
    font-size: 112.5%;
    font-weight: 700;
    line-height: 2.75rem;
    text-decoration: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a.active {
    text-decoration: underline;
  }
  .offcanvas_menu .offcanvas_menu_content > ul li.expanded > ul {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul:target {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a {
    color: #fff;
    display: block;
    padding: 0.5em 2em 0.5em 0;
    text-decoration: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a.active {
    text-decoration: underline;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul li {
    padding-left: 1em;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul ul {
    border-bottom: 0.0625rem solid rgba(78, 142, 189, 0.2);
    border-top: 0.0625rem solid rgba(78, 142, 189, 0.2);
  }
  .onlinetools_popup_overview {
    margin-top: 4em;
  }
  .onlinetools_popup_overview .overview_title {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-bottom: 2em;
  }
  .onlinetools_popup_overview .overview_title .icon {
    display: block;
    fill: #4b4b4b;
    height: 4.125rem;
    position: relative;
    text-align: center;
    width: 4.125rem;
    z-index: 2;
  }
  .onlinetools_popup_overview .overview_title .icon:after {
    background-image: url("../images/leaf_main.svg");
    background-position: center center;
    background-size: cover;
    content: "";
    display: block;
    height: 7.5rem;
    left: -2.8125rem;
    opacity: 0.5;
    position: absolute;
    top: -2.1875rem;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    width: 7.5rem;
    z-index: -1;
  }
  .onlinetools_popup_overview .overview_title:hover .icon::after {
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-name: leaves;
            animation-name: leaves;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    opacity: 0;
    position: absolute;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    z-index: -1;
  }
  .onlinetools_popup_overview .entry {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin: 0 0 2em;
    padding: 0 2em;
    position: relative;
  }
  .onlinetools_popup_overview .entry:hover {
    background: rgba(75, 75, 75, 0.1);
    -webkit-transition: background 300ms;
    transition: background 300ms;
  }
  .onlinetools_popup_overview .description {
    display: block;
    margin-bottom: 1em;
  }
  .onlinetools_popup_overview .touch_link .title {
    margin: 0 0 0.5em;
    padding: 0;
  }
  .popup_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(75, 75, 75, 0.5);
    bottom: 0;
    left: 0;
    opacity: 0;
    padding: 1% 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
    z-index: -1;
  }
  .popup_overlay.show {
    opacity: 1;
    visibility: visible;
    z-index: 9000;
  }
  .popup_header {
    padding: 0 0 0 2em;
  }
  .popup_close {
    -webkit-box-align: center;
            align-items: center;
    background: #73a6cb;
    border-radius: 0.125rem 0.125rem 0 0;
    display: -webkit-box;
    display: flex;
    float: right;
    -webkit-box-pack: center;
            justify-content: center;
    margin-right: 0.0625rem;
    padding: 0.57142857em;
    text-align: center;
    height: 2.75rem;
    width: 2.75rem;
  }
  .popup_close .icon {
    fill: #fff;
    height: 1.5rem;
    width: 1.5rem;
  }
  .popup_content {
    height: 100%;
    margin: 0 auto;
    max-width: 100rem;
    opacity: 0;
    position: relative;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    -webkit-transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms, -webkit-transform 300ms;
    visibility: hidden;
    z-index: 9500;
  }
  .popup_content.show {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: visible;
  }
  .popup_content .content {
    background: #fff;
    bottom: 0;
    box-shadow: 0 0 1.5625rem rgba(75, 75, 75, 0.5);
    left: 0;
    position: absolute;
    right: 0;
    top: 2.75rem;
  }
  .popup_iframe {
    background: #fff;
    border: 0;
    height: 100%;
    opacity: 0;
    position: relative;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    width: 100%;
    z-index: 1;
  }
  .popup_iframe.show {
    opacity: 1;
  }
  .opening_times .title {
    padding-top: 1em;
  }
  .opening_times .entries {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .opening_times .entry {
    margin-right: 3em;
  }
  .preloader {
    display: block;
    position: relative;
  }
  .preloader .icon_spin {
    -webkit-animation: preloader_rotate 900ms linear infinite;
            animation: preloader_rotate 900ms linear infinite;
    display: block;
    fill: rgba(78, 142, 189, 0.2);
    height: 3rem;
    left: 50%;
    margin: -1.5rem 0 0 -1.5rem;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: visible;
    width: 3rem;
  }
  .preloader .icon_spin.hide {
    opacity: 0;
    visibility: hidden;
  }
  .no-cssanimations .icon_spin {
    display: none;
  }
  .toggle_search_box {
    align-self: center;
    display: none;
    height: 2.125rem;
    margin-left: 1em;
    position: relative;
    width: 2.125rem;
  }
  .toggle_search_box label {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }
  .toggle_search_box label:hover ~ a {
    fill: #2d5978;
  }
  .toggle_search_box a {
    fill: #73a6cb;
  }
  .toggle_search_box a .icon {
    display: block;
    height: 2.125rem;
    position: relative;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.125rem;
    z-index: 1;
  }
  .toggle_search_box .open_search_box {
    display: block;
  }
  .toggle_search_box .close_search_box {
    display: none;
  }
  .search_box {
    -webkit-backdrop-filter: blur(0.125rem);
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    padding: 1em;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
    z-index: 9999;
  }
  .search_box .close_search_box_wrapper {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .search_box .close_search_box_wrapper label {
    background: rgba(75, 75, 75, 0.5);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
  }
  .search_box .close_search_box_wrapper .close_search_box {
    -webkit-box-align: center;
            align-items: center;
    background: #73a6cb;
    border: 0.0625rem solid #73a6cb;
    border-radius: 0.125rem;
    box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.2);
    fill: #fff;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    padding: 0.25rem;
    position: absolute;
    right: 2em;
    top: 2em;
    width: 2.75rem;
  }
  .search_box .close_search_box_wrapper .close_search_box .icon {
    display: block;
    height: 2.125rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.125rem;
  }
  .search_box .close_search_box_wrapper .close_search_box:focus {
    background: #98bed9;
    border-color: #98bed9;
    outline: 0;
  }
  .search_box .close_search_box_wrapper .close_search_box:hover {
    background: #98bed9;
    border-color: #98bed9;
    color: #fff;
  }
  .search_box .close_search_box_wrapper .close_search_box:hover .icon {
    fill: #fff;
  }
  .search_box form[role="search"] {
    background: #fff;
    border: 0.625rem solid #fff;
    border-radius: 0.125rem;
    box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.2);
    display: -webkit-box;
    display: flex;
    opacity: 0;
    position: relative;
    -webkit-transform: translateY(-6.25rem);
            transform: translateY(-6.25rem);
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: hidden;
    width: 37.5rem;
    z-index: 1000;
  }
  .search_box form[role="search"] button {
    width: 2.75rem;
    -webkit-box-flex: 0;
            flex-grow: 0;
    flex-shrink: 0;
  }
  .search_box input[type="search"] {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    font-size: 115%;
    -webkit-user-select: text;
       -moz-user-select: text;
        -ms-user-select: text;
            user-select: text;
  }
  .search_box_state {
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
  }
  .search_box_state:checked ~ .search_box,
  .search_box_state:target ~ .search_box {
    opacity: 1;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box .close_search_box_wrapper label,
  .search_box_state:target ~ .search_box .close_search_box_wrapper label {
    opacity: 1;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box form[role="search"],
  .search_box_state:target ~ .search_box form[role="search"] {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    visibility: visible;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box {
    display: none;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box {
    display: -webkit-box;
    display: flex;
  }
  form[role="search"] {
    display: -webkit-box;
    display: flex;
  }
  form[role="search"] button {
    border-radius: 0 0.125rem 0.125rem 0;
    -webkit-box-flex: 0;
            flex-grow: 0;
    flex-shrink: 0;
    margin: 0;
    padding: 0;
    -webkit-box-align: center;
            align-items: center;
    background: #73a6cb;
    border: 0.0625rem solid #73a6cb;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 2.75rem;
  }
  form[role="search"] button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
  }
  form[role="search"] button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    line-height: 1;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  form[role="search"] button .text {
    font-weight: 400;
    line-height: 1;
    padding: 0.5em 0;
  }
  form[role="search"] button .icon + .text {
    padding-left: 0.66666667em;
  }
  form[role="search"] button[disabled] {
    background-color: rgba(115, 166, 203, 0.2);
    border-color: rgba(96, 154, 196, 0.2);
    color: #cbcbcb;
  }
  form[role="search"] button[disabled] .icon {
    fill: #4b4b4b;
  }
  form[role="search"] button > span {
    width: 100%;
  }
  form[role="search"] button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(115, 166, 203, 0.2);
    position: relative;
    z-index: 10;
    background: #98bed9;
    border-color: #98bed9;
    color: #fff;
  }
  form[role="search"] button:focus .icon {
    fill: #fff;
  }
  form[role="search"] button .icon {
    height: 2.625rem;
  }
  input[type="search"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: #fff;
    border: 0.0625rem solid rgba(78, 142, 189, 0.2);
    border-radius: 0.125rem 0 0 0.125rem;
    box-shadow: 0 0 0 rgba(78, 142, 189, 0.2) inset;
    color: #4b4b4b;
    flex-basis: 100%;
    margin: 0;
    padding: 0 1em;
    -webkit-transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms, z-index 300ms;
    transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms, z-index 300ms;
    height: 2.75rem;
  }
  input[type="search"]:focus {
    background: #fff;
    border-color: #73a6cb;
    box-shadow: 0 0 0.1875rem #73a6cb inset, 0 0 0 0.1875rem rgba(115, 166, 203, 0.2);
    color: #4b4b4b;
    outline: 0;
    position: relative;
    z-index: 10;
  }
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  .search dl {
    margin: 0 0 1em;
    padding-top: 0.5em;
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .search dd {
    margin: 0;
  }
  .offcanvas_search {
    margin-bottom: 1em;
    padding: 0 1em;
  }
  .offcanvas_search input {
    width: 90%;
  }
  .offcanvas_search button {
    background-color: #fff !important;
  }
  .offcanvas_search button .icon {
    fill: #73a6cb !important;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .social_media_legal_notice {
    padding: 2em;
  }
  .social_media_legal_notice .company_logo {
    margin: 0 auto;
    max-width: 25rem;
    text-align: center;
  }
  .social_media_links {
    position: absolute;
    right: 1.33333333em;
    top: 9em;
    z-index: 2;
  }
  .social_media_links ul {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin: 0;
    padding: 0;
  }
  .social_media_links li {
    list-style: none;
    margin: 0;
    padding: 0 0 0.66666667em 0;
  }
  .social_media_links a {
    border-radius: 0.125rem;
    display: inline-block;
  }
  .social_media_links a .icon {
    display: block;
    fill: #fff;
    height: 2.125rem;
    width: 2.125rem;
  }
  .social_media_links a.video {
    background: #73a6cb;
  }
  .social_media_links a.facebook .icon,
  .social_media_links a.twitter .icon {
    border-radius: 0;
  }
  .social_media_share ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin: 2em 0 1em 0;
    padding: 0;
  }
  .social_media_share li {
    list-style: none;
    margin: 0.5em 0;
    padding: 0;
  }
  .social_media_share a {
    color: #4b4b4b;
    cursor: pointer;
    display: block;
    margin-right: 1em;
    padding-right: 1em;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .social_media_share a > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
  }
  .social_media_share a .icon {
    border-radius: 0.125rem;
    height: 2.125rem;
    width: 2.125rem;
  }
  .social_media_share a .text {
    font-weight: 400;
    padding-left: 1em;
  }
  .social_media_share a.print {
    display: none;
  }
  .social_media_share a.print .icon {
    background: #73a6cb;
    text-align: center;
  }
  .social_media_share a.print .icon svg {
    fill: #fff;
    height: 2.125rem;
    width: 1.5rem;
  }
  .social_media_share a.facebook .icon,
  .social_media_share a.twitter .icon {
    border-radius: 0;
  }
  .steuernews_navigation {
    margin-bottom: 1em;
  }
  .steuernews_navigation ul {
    list-style: url("../images/listicon.svg") !important;
    margin: 0 0 1em;
    padding-left: 1.5em;
  }
  .steuernews_navigation ul > li {
    line-height: 1.5;
    margin-bottom: 1em;
    padding-left: 0.5em;
  }
  .steuernews_navigation ul a {
    text-decoration: none;
  }
  .steuernews_navigation ul a:hover {
    text-decoration: underline;
  }
  .steuernews_navigation ul a:active {
    text-decoration: underline;
  }
  .steuernews_content [href*="thema"] {
    color: #4b4b4b;
  }
  .steuernews_preview {
    margin-bottom: 1em;
  }
  .steuernews_preview .entry {
    display: -webkit-box;
    display: flex;
    margin-bottom: 2em;
  }
  .steuernews_preview .image {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0;
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .steuernews_preview .image img {
    margin-right: 2em;
  }
  .steuernews_preview .teaser_text {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .steuernews_preview .title {
    word-break: break-all;
  }
  .steuernews_preview .video_play {
    position: relative;
  }
  .steuernews_preview .video_play .icon {
    fill: #73a6cb;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .steuernews_article_overview .entry {
    background-position: center center !important;
    background-size: cover;
    height: 18.75rem;
    position: relative;
  }
  .steuernews_article_overview .entry:hover .description .news_title {
    text-decoration: underline;
  }
  .steuernews_article_overview .description {
    background: rgba(255, 255, 255, 0.8);
    bottom: 0;
    color: #4b4b4b;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
    font-weight: 800;
    -webkit-box-pack: end;
            justify-content: flex-end;
    left: 0;
    padding: 1em;
    position: absolute;
    right: 0;
    text-align: left;
    -webkit-transition: all 150ms;
    transition: all 150ms;
  }
  .steuernews_article_overview .description .title_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    font-family: "Palanquin Dark", sans-serif;
    -webkit-box-pack: center;
            justify-content: center;
    line-height: 1.6;
    margin-left: 2em;
    text-transform: uppercase;
    width: 100%;
  }
  .steuernews_article_overview .description .title_wrapper span {
    display: block;
  }
  .steuernews_article_overview .description .vorwort {
    font-family: "Merriweather", sans-serif;
    font-size: 90%;
    text-transform: none;
  }
  .steuernews_article_overview .description .sub_title {
    align-content: center;
    -webkit-box-align: start;
            align-items: flex-start;
    color: #4b4b4b;
    display: none;
    font-family: "Palanquin Dark", sans-serif;
    font-weight: 300;
    -webkit-box-pack: center;
            justify-content: center;
    position: relative;
  }
  .steuernews_article_overview .description .sub_title time {
    z-index: 11;
  }
  .steuernews_article_overview .description .sub_title .wochentag {
    display: none;
  }
  .steuernews_article_overview .description .sub_title .number {
    display: block;
    font-size: 250%;
    font-weight: bold;
    line-height: 0.8;
  }
  .steuernews_article_overview .description .sub_title .month {
    display: block;
    font-size: 90%;
    text-align: center;
  }
  .steuernews_article_overview .description .sub_title .year {
    display: none;
  }
  .steuernews_article_overview .description .sub_title::after {
    background-color: #fff;
    border-radius: 100%;
    content: "";
    height: 4.125rem;
    left: -40%;
    position: absolute;
    top: -0.625rem;
    width: 4.125rem;
    z-index: 10;
  }
  .steuernews_search {
    margin: 0 0 2em;
  }
  .steuernews_search .search {
    margin: 0 0 1em;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  .news_image {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    margin-left: 2em;
  }
  .news_image + * {
    padding-top: 0;
  }
  .steuernews_video_overview {
    margin-bottom: 2em;
  }
  .steuernews_video_overview .entry {
    display: inline-block;
  }
  .steuernews_video_overview .image {
    margin-bottom: 1em;
    position: relative;
  }
  .steuernews_video_overview .video_play {
    position: relative;
  }
  .steuernews_video_overview .video_play .icon {
    fill: #73a6cb;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .steuernews_contact_person {
    background: rgba(115, 166, 203, 0.2);
    border-radius: 0.125rem;
    margin-bottom: 2em;
    padding: 2em;
  }
  .steuernews_contact_person .team_entry {
    display: -webkit-box;
    display: flex;
  }
  .steuernews_contact_person .team_entry figure {
    display: block;
  }
  .steuernews_contact_person .contact_person_entry {
    height: auto;
    margin-top: 2em;
    position: relative;
  }
  .steuernews_contact_person .more_team_contacts {
    background: #fff;
    border-radius: 0.125rem;
    color: #73a6cb;
    display: block;
    line-height: 2;
    margin: 1em 0;
    padding: 1em;
    text-align: center;
  }
  .steuernews_newsletter_subscribe {
    background: rgba(115, 166, 203, 0.2);
    border-radius: 0.125rem;
    margin-bottom: 2em;
    padding: 2em;
  }
  .steuernews_newsletter_subscribe .disclaimer {
    background-color: #fff;
  }
  .steuernews_sidebar {
    margin: 2em 0 1em;
  }
  .steuernews_current_issue {
    margin-bottom: 1em;
  }
  .steuernews_current_issue ul {
    list-style: url("../images/listicon.svg") !important;
    margin: 0 0 1em;
    padding-left: 1.5em;
  }
  .steuernews_current_issue ul > li {
    line-height: 1.5;
    margin-bottom: 1em;
    padding-left: 0.5em;
  }
  .steuernews_current_issue ul a {
    text-decoration: none;
  }
  .steuernews_current_issue ul a:hover {
    text-decoration: underline;
  }
  .steuernews_current_issue ul a:active {
    text-decoration: underline;
  }
  .steuernews_issues {
    margin-bottom: 1em;
  }
  .steuernews_issues ul {
    list-style: url("../images/listicon.svg") !important;
    margin: 0 0 1em;
    padding-left: 1.5em;
  }
  .steuernews_issues ul > li {
    line-height: 1.5;
    margin-bottom: 1em;
    padding-left: 0.5em;
  }
  .steuernews_issues ul a {
    text-decoration: none;
  }
  .steuernews_issues ul a:hover {
    text-decoration: underline;
  }
  .steuernews_issues ul a:active {
    text-decoration: underline;
  }
  .highlight_keyword {
    background: #73a6cb;
    color: #fff;
    outline: 0.125rem solid #73a6cb;
  }
  .team_overview .row .center {
    margin-left: auto;
    margin-right: auto;
  }
  .team_overview .entry {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    margin-bottom: 4em;
    overflow: hidden;
    position: relative;
    -webkit-transition: -webkit-filter 300ms;
    transition: -webkit-filter 300ms;
    transition: filter 300ms;
    transition: filter 300ms, -webkit-filter 300ms;
  }
  .team_overview .entry .info {
    text-align: center;
    z-index: 10;
  }
  .team_overview .entry .info .title {
    letter-spacing: calc((1rem + 0.125
                                 * (100vw - 18.75rem)
                                 / (75))
                                 / 1000 * 0);
    font-size: 1rem;
    color: #73a6cb;
    font-weight: 700;
    line-height: 2;
    padding: 0;
    position: relative;
  }
  .team_overview .entry .info .title a {
    text-decoration: none;
  }
  .team_overview .entry .info .title::before {
    display: none;
  }
  .team_overview .entry .info p[itemprop="jobTitle"] {
    margin-bottom: 1em;
    margin-top: 2em;
  }
  .team_overview .entry .info p {
    font-size: 0.75rem;
    margin-bottom: 0;
  }
  .team_overview .entry .info .contact {
    display: -webkit-inline-box;
    display: inline-flex;
  }
  .team_overview .entry .info .contact strong {
    margin-right: 1em;
    min-width: auto;
  }
  .team_overview .entry .info .contact .email {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .team_overview .entry .info:hover .title a {
    text-decoration: underline;
  }
  .team_overview .photo {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin-bottom: 1em;
  }
  .team_overview .photo img {
    display: block;
  }
  .team_entry .info {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    -webkit-box-pack: center;
            justify-content: center;
    margin-bottom: 1em;
  }
  .team_entry .info .contact {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 18.75rem;
  }
  .teaser_overview .teaser {
    margin: 3em 0 2em;
  }
  .teaser_overview .teaser a {
    -webkit-box-align: center;
            align-items: center;
    color: #4b4b4b;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    text-decoration: none;
  }
  .teaser_overview .teaser .content {
    width: 100%;
  }
  .teaser_overview .teaser a:hover .content {
    background: rgba(75, 75, 75, 0.1);
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 100%;
  }
  .teaser_overview .teaser a:hover .icon::after {
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-name: leaves;
            animation-name: leaves;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    opacity: 0;
    position: absolute;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    z-index: -1;
  }
  .teaser_overview .teaser .content {
    margin-top: 0.66666667em;
    padding: 0.33333333em 1em;
    -webkit-transition: background 300ms;
    transition: background 300ms;
  }
  .teaser_overview .teaser .icon {
    display: block;
    fill: #4b4b4b;
    height: 4.125rem;
    margin-left: 3.125rem;
    position: relative;
    width: 4.125rem;
    z-index: 2;
  }
  .teaser_overview .teaser .icon:after {
    background-image: url("../images/leaf_main.svg");
    background-position: center center;
    background-size: cover;
    content: "";
    display: block;
    height: 7.5rem;
    left: -2.8125rem;
    opacity: 0.5;
    position: absolute;
    top: -2.1875rem;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 7.5rem;
    z-index: -1;
  }
  .teaser_overview .teaser .title {
    font-size: 90%;
    margin-bottom: 0.5em;
    text-transform: uppercase;
  }
  .teaser_overview .teaser p {
    font-size: 90%;
  }
  .tooltip_wrapper {
    display: block;
    position: relative;
  }
  [role="tooltip"] {
    background: rgba(115, 166, 203, 0.2);
    border: 0.0625rem solid rgba(78, 142, 189, 0.2);
    border-radius: 0.125rem;
    bottom: 100%;
    color: #4b4b4b;
    font-size: 85%;
    font-weight: 400;
    line-height: 2;
    padding: 1em;
    position: absolute;
    right: 0;
    text-align: center;
    -webkit-transform: translateY(-0.3125rem);
            transform: translateY(-0.3125rem);
    z-index: 50;
  }
  [role="tooltip"]:after {
    border: 0.625rem solid transparent;
    border-top-color: rgba(115, 166, 203, 0.2);
    bottom: -1.25rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.625rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"]:before {
    border: 0.6875rem solid transparent;
    border-top-color: rgba(78, 142, 189, 0.2);
    bottom: -1.375rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.6875rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"][aria-hidden="true"] {
    display: none;
  }
  .swiper-container {
    list-style: none;
    margin: 4em auto 0 auto;
    overflow: hidden;
    padding: 0 0 4em 1.53846154em;
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .swiper-slide {
    -webkit-box-align: center;
            align-items: center;
    background-position: center center;
    background-size: cover;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .swiper-slide .entry {
    background-position: center center;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .swiper-slide .entry:hover .description .news_title {
    text-decoration: underline;
  }
  .swiper-slide .description {
    background: rgba(255, 255, 255, 0.8);
    bottom: 0;
    color: #4b4b4b;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
    font-weight: 800;
    -webkit-box-pack: end;
            justify-content: flex-end;
    left: 0;
    padding: 1em;
    position: absolute;
    right: 0;
    text-align: left;
    -webkit-transition: all 150ms;
    transition: all 150ms;
  }
  .swiper-slide .description .title_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    font-family: "Palanquin Dark", sans-serif;
    -webkit-box-pack: center;
            justify-content: center;
    line-height: 1.6;
    margin-left: 2em;
    text-transform: uppercase;
    width: 100%;
  }
  .swiper-slide .description .title_wrapper span {
    display: block;
  }
  .swiper-slide .description .news_title {
    font-family: "Merriweather", sans-serif;
    font-size: 90%;
    text-transform: none;
  }
  .swiper-slide .description .sub_title {
    align-content: center;
    -webkit-box-align: start;
            align-items: flex-start;
    color: #4b4b4b;
    display: none;
    font-family: "Palanquin Dark", sans-serif;
    font-weight: 300;
    -webkit-box-pack: center;
            justify-content: center;
    position: relative;
  }
  .swiper-slide .description .sub_title time {
    z-index: 11;
  }
  .swiper-slide .description .sub_title .wochentag {
    display: none;
  }
  .swiper-slide .description .sub_title .number {
    display: block;
    font-size: 250%;
    font-weight: bold;
    line-height: 0.8;
  }
  .swiper-slide .description .sub_title .month {
    display: block;
    font-size: 90%;
    text-align: center;
  }
  .swiper-slide .description .sub_title .year {
    display: none;
  }
  .swiper-slide .description .sub_title::after {
    background-color: #fff;
    border-radius: 100%;
    content: "";
    height: 4.125rem;
    left: -40%;
    position: absolute;
    top: -0.625rem;
    width: 4.125rem;
    z-index: 10;
  }
  .swiper-container-no-flexbox .swiper-slide {
    float: left;
  }
  .swiper-container-vertical > .swiper-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .swiper-wrapper {
    box-sizing: content-box;
    display: -webkit-box;
    display: flex;
    height: 100%;
    position: relative;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    width: 100%;
    z-index: 1;
  }
  .swiper-container-android .swiper-slide,
  .swiper-wrapper {
    -webkit-transform: translate3d(0rem, 0, 0);
            transform: translate3d(0rem, 0, 0);
  }
  .swiper-container-multirow > .swiper-wrapper {
    flex-wrap: wrap;
  }
  .swiper-container-free-mode > .swiper-wrapper {
    margin: 0 auto;
    -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
  }
  .swiper-slide {
    flex-shrink: 0;
    height: 25rem;
    position: relative;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    width: 100%;
  }
  .swiper-slide-invisible-blank {
    visibility: hidden;
  }
  /* Auto Height */
  .swiper-container-autoheight,
  .swiper-container-autoheight .swiper-slide {
    height: auto;
  }
  .swiper-container-autoheight .swiper-wrapper {
    -webkit-box-align: start;
            align-items: flex-start;
    -webkit-transition-property: height, -webkit-transform;
    transition-property: height, -webkit-transform;
    transition-property: transform, height;
    transition-property: transform, height, -webkit-transform;
  }
  .swiper-button-prev,
  .swiper-button-next {
    align-content: center;
    -webkit-box-align: center;
            align-items: center;
    background-color: #73a6cb;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.6875rem 2.75rem;
    border-radius: 50%;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    fill: #fff;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin-top: -1.375rem;
    position: absolute;
    top: 16.5%;
    width: 2.75rem;
    z-index: 10;
  }
  .swiper-button-prev .icon,
  .swiper-button-next .icon {
    height: 1.375rem;
    width: 1.375rem;
  }
  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    cursor: auto;
    opacity: 0.35;
    pointer-events: none;
  }
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: 25%;
    right: auto;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    left: auto;
    right: 25%;
  }
  .swiper-button-lock {
    display: none;
  }
  .swiper-pagination {
    padding-left: 1.53846154em;
    position: absolute;
    text-align: left;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-transition: 300ms opacity;
    transition: 300ms opacity;
    z-index: 10;
  }
  .swiper-pagination.swiper-pagination-hidden {
    opacity: 0;
  }
  /* Common Styles */
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0.625rem;
    left: 0;
    width: 100%;
  }
  .swiper-pagination-bullet {
    background: transparent;
    border: 0.125rem solid #4b4b4b;
    border-radius: 100%;
    display: inline-block;
    height: 1rem;
    margin-right: 0.5em;
    opacity: 0.5;
    width: 1rem;
  }
  button .swiper-pagination-bullet {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border: 0;
    box-shadow: none;
    margin: 0;
    padding: 0;
  }
  .swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer;
  }
  .swiper-pagination-bullet-active {
    background: #4b4b4b;
    opacity: 0.5;
  }
  .swiper-container.news_slider {
    margin: 0;
  }
  .swiper-container.news_slider .swiper-pagination {
    text-align: center;
  }
}
@media screen and (min-width: 61.875rem) {
  aside {
    margin: 0;
  }
}
@media screen and (min-width: 18.75rem) {
  .button {
    min-height: calc(2.625rem + 0.625
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media screen and (min-width: 93.75rem) {
  .button {
    min-height: 3.25rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .chart_organigram {
    display: block;
    margin-bottom: 1em;
  }
}
@media screen and (min-width: 18.75rem) {
  .company_logo {
    max-width: calc(11.25rem + 2.5
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media screen and (min-width: 93.75rem) {
  .company_logo {
    max-width: 13.75rem;
  }
}
@media screen and (min-width: 35rem) {
  .download_overview {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5rem) {
  .download_overview .touch_link {
    display: -webkit-box;
    display: flex;
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 75rem) {
  .download_overview .touch_link {
    flex-basis: 100%;
    max-width: 100%;
    padding: 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .dropdown_menu {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    font-family: "Palanquin Dark", sans-serif;
    width: 100%;
  }
}
@media screen and (min-width: 61.875rem) {
  .main_footer .address {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 35rem) {
  .main_footer .related_links {
    -webkit-box-pack: center;
            justify-content: center;
  }
}
@media screen and (min-width: 61.875rem) {
  .main_footer .related_links {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
}
@media screen and (min-width: 35rem) {
  .main_footer .related_links ul {
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 18.75rem) {
  .file {
    height: calc(2.75rem + 0.625
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file {
    height: 3.375rem;
  }
}
@media screen and (min-width: 18.75rem) {
  .file .file_name {
    line-height: calc(2.625rem + 0.625
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_name {
    line-height: 3.25rem;
  }
}
@media screen and (min-width: 18.75rem) {
  .file .file_name {
    height: calc(2.75rem + 0.625
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_name {
    height: 3.375rem;
  }
}
@media screen and (min-width: 18.75rem) {
  .file [type="file"] {
    height: calc(2.75rem + 0.625
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file [type="file"] {
    height: 3.375rem;
  }
}
@media screen and (min-width: 35rem) {
  .formular .button_wrapper {
    text-align: right;
  }
}
@media screen and (min-width: 18.75rem) {
  button {
    height: calc(2.75rem + 0.625
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media screen and (min-width: 93.75rem) {
  button {
    height: 3.375rem;
  }
}
@media screen and (min-width: 35rem) {
  button {
    width: auto;
  }
}
@media screen and (min-width: 18.75rem) {
  .google_maps .travel_mode_entry {
    height: calc(2.75rem + 0.625
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .travel_mode_entry {
    height: 3.375rem;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .step:before {
    font-size: inherit;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .instructions,
  .google_maps .distance {
    font-size: inherit;
  }
}
@media screen and (min-width: 18.75rem) {
  .google_maps .infowindow {
    max-width: calc(13.75rem + 8.75
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .infowindow {
    max-width: 22.5rem;
  }
}
@media screen and (min-width: 18.75rem) {
  .container {
    padding: 0 calc(0.9375rem + 2.1875
                                         * (100vw - 18.75rem)
                                         / (75));
  }
  @supports (padding-left: env(safe-area-inset-left)) {
    html.mobile .container {
      padding-left: max(calc(0.9375rem + 2.1875
                                         * (100vw - 18.75rem)
                                         / (75)), env(safe-area-inset-left));
      padding-right: max(calc(0.9375rem + 2.1875
                                         * (100vw - 18.75rem)
                                         / (75)), env(safe-area-inset-right));
    }
  }
}
@media screen and (min-width: 93.75rem) {
  .container {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 18.75rem) {
  .container_small {
    padding: 0 calc(0.9375rem + 2.1875
                                         * (100vw - 18.75rem)
                                         / (75));
  }
  @supports (padding-left: env(safe-area-inset-left)) {
    html.mobile .container_small {
      padding-left: max(calc(0.9375rem + 2.1875
                                         * (100vw - 18.75rem)
                                         / (75)), env(safe-area-inset-left));
      padding-right: max(calc(0.9375rem + 2.1875
                                         * (100vw - 18.75rem)
                                         / (75)), env(safe-area-inset-right));
    }
  }
}
@media screen and (min-width: 93.75rem) {
  .container_small {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 18.75rem) {
  .row {
    margin: 0 calc((-0.9375rem / 2) - (2.1875 / 2)
                                         * (100vw - 18.75rem)
                                         / (75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row {
    margin: 0 -1.5625rem;
  }
}
@media screen and (min-width: 18.75rem) {
  .row.half_column_margin {
    margin: 0 calc((-0.9375rem / 4) - (2.1875 / 4)
                                         * (100vw - 18.75rem)
                                         / (75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin {
    margin: 0 -0.78125rem;
  }
}
@media screen and (min-width: 18.75rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 calc((0.9375rem / 4) + (2.1875 / 4)
                                         * (100vw - 18.75rem)
                                         / (75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 0.78125rem;
  }
}
@media screen and (min-width: 30rem) {
  .row.around_small {
    justify-content: space-around;
  }
  .row.between_small {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_small {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_small {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_small {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_small {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_small {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_small {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_small {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 35rem) {
  .row.around_medium {
    justify-content: space-around;
  }
  .row.between_medium {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_medium {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_medium {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_medium {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_medium {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_medium {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_medium {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_medium {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 47.5rem) {
  .row.around_large {
    justify-content: space-around;
  }
  .row.between_large {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_large {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_large {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_large {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_large {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_large {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_large {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_large {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 61.875rem) {
  .row.around_xlarge {
    justify-content: space-around;
  }
  .row.between_xlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 75rem) {
  .row.around_xxlarge {
    justify-content: space-around;
  }
  .row.between_xxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 93.75rem) {
  .row.around_xxxlarge {
    justify-content: space-around;
  }
  .row.between_xxxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 18.75rem) {
  [class*="span"] {
    padding: 0 calc((0.9375rem / 2) + (2.1875 / 2)
                                         * (100vw - 18.75rem)
                                         / (75));
  }
}
@media screen and (min-width: 93.75rem) {
  [class*="span"] {
    padding: 0 1.5625rem;
  }
}
@media screen and (min-width: 30rem) {
  .span1_small {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_small {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_small {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_small {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_small {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_small {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_small {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_small {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_small {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_small {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_small {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_small {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_small {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_small {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_small {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_small {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_small {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_small {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_small {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_small {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_small {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_small {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_small {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_small {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 35rem) {
  .span1_medium {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_medium {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_medium {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_medium {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_medium {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_medium {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_medium {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_medium {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_medium {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_medium {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_medium {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_medium {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_medium {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_medium {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_medium {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_medium {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_medium {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_medium {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_medium {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_medium {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_medium {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_medium {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_medium {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_medium {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5rem) {
  .span1_large {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_large {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_large {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_large {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_large {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_large {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_large {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_large {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_large {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_large {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_large {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_large {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_large {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_large {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_large {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_large {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_large {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_large {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_large {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_large {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_large {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_large {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_large {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_large {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 61.875rem) {
  .span1_xlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 75rem) {
  .span1_xxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 93.75rem) {
  .span1_xxxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5rem) {
  .header_background {
    padding-bottom: 40%;
  }
}
@media screen and (min-width: 47.5rem) {
  .header_background .icon {
    height: 2.625rem;
    width: 2.625rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .header_background:after {
    height: 12.5rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .main_header {
    position: absolute;
  }
}
@media screen and (min-width: 47.5rem) {
  .img_container.u_online_svg_2_small {
    display: none;
  }
}
@media screen and (min-width: 47.5rem) {
  .img_container.u_online_svg_2 {
    display: block;
  }
}
@media screen and (min-width: 47.5rem) {
  .u_online_2_headline {
    font-size: 80%;
  }
}
@media screen and (min-width: 47.5rem) {
  .u_online_text {
    font-size: 80%;
  }
}
@media screen and (min-width: 18.75rem) {
  .u_online_benefits_flow .benefits_button .text {
    line-height: calc(2.75rem + 0.625
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media screen and (min-width: 93.75rem) {
  .u_online_benefits_flow .benefits_button .text {
    line-height: 3.375rem;
  }
}
@media screen and (min-width: 18.75rem) {
  .u_online_benefits_flow .benefits_button:after {
    height: calc(2.75rem + 0.625
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media screen and (min-width: 93.75rem) {
  .u_online_benefits_flow .benefits_button:after {
    height: 3.375rem;
  }
}
@media screen and (min-width: 18.75rem) {
  .u_online_benefits_flow .benefits_button:after {
    width: calc(2.75rem + 0.625
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media screen and (min-width: 93.75rem) {
  .u_online_benefits_flow .benefits_button:after {
    width: 3.375rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .u_online_benefits_flow .table {
    display: table;
    margin: 0 auto;
    padding: 0;
  }
  .u_online_benefits_flow .table_cell {
    display: table-cell;
    padding: 0 2em;
    vertical-align: middle;
  }
  .u_online_benefits_flow .table_cell.text {
    max-width: 31.25rem;
  }
  .u_online_benefits_flow .arrow_large {
    display: block;
  }
  .u_online_benefits_flow .arrow_small {
    display: none;
  }
  .u_online_benefits_flow .flow_text {
    display: block;
  }
  .u_online_benefits_flow .header {
    display: block;
    text-align: center;
  }
  .u_online_benefits_flow .header .text {
    display: block;
    margin: 0 auto 1em;
    padding: 0;
    text-align: center;
    width: 80%;
  }
  .u_online_benefits_flow .footer {
    display: block;
    text-align: center;
  }
  .u_online_benefits_flow .footer .text {
    display: block;
    margin: 1em auto 0;
    padding: 0;
    text-align: center;
    width: 80%;
  }
  .u_online_benefits_flow .footer .arrow_large {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .u_online_benefits_flow .description {
    max-height: none;
    overflow: none;
    padding: 0 1em;
  }
  .u_online_benefits_flow .lock {
    margin-bottom: 2em;
  }
  .u_online_benefits_flow .lock:before {
    height: 3.5rem;
    width: 3.5rem;
  }
  .u_online_benefits_flow .benefits_button {
    display: none;
  }
}
@media screen and (min-width: 47.5rem) {
  .multi_columns {
    -webkit-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 4em;
            column-gap: 4em;
  }
}
@media screen and (min-width: 61.875rem) {
  .news_section {
    margin: 8em 0 0 0;
    padding: 7em 0 4em 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .startpage_onlinetool_overview_wrapper {
    margin-top: -9em;
  }
}
@media screen and (min-width: 75rem) {
  .sidebar_links {
    margin-top: 0;
  }
}
@media screen and (min-width: 35rem) {
  .useful_link_overview {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5rem) {
  .useful_link_overview .touch_link {
    display: -webkit-box;
    display: flex;
    flex-basis: 50%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    max-width: 50%;
  }
}
@media screen and (min-width: 75rem) {
  .useful_link_overview .touch_link {
    flex-basis: 100%;
    max-width: 100%;
    padding: 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .toggle_offcanvas_menu {
    display: none;
  }
}
@media screen and (max-width: 61.8125rem) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay {
    opacity: 1;
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu .open_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu .close_offcanvas_menu {
    display: block;
  }
}
@media screen and (min-width: 30rem) {
  .offcanvas_menu {
    width: 21.875rem;
  }
}
@media screen and (min-width: 18.75rem) {
  .popup_close {
    height: calc(2.75rem + 0.625
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_close {
    height: 3.375rem;
  }
}
@media screen and (min-width: 18.75rem) {
  .popup_close {
    width: calc(2.75rem + 0.625
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_close {
    width: 3.375rem;
  }
}
@media screen and (min-width: 18.75rem) {
  .popup_content .content {
    top: calc(2.75rem + 0.625
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_content .content {
    top: 3.375rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .toggle_search_box {
    display: block;
  }
}
@media screen and (min-width: 18.75rem) {
  .search_box form[role="search"] button {
    width: calc(2.75rem + 0.625
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media screen and (min-width: 93.75rem) {
  .search_box form[role="search"] button {
    width: 3.375rem;
  }
}
@media screen and (min-width: 18.75rem) {
  form[role="search"] button {
    width: calc(2.75rem + 0.625
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media screen and (min-width: 93.75rem) {
  form[role="search"] button {
    width: 3.375rem;
  }
}
@media screen and (min-width: 18.75rem) {
  form[role="search"] button .icon {
    height: calc(2.625rem + 0.625
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media screen and (min-width: 93.75rem) {
  form[role="search"] button .icon {
    height: 3.25rem;
  }
}
@media screen and (min-width: 18.75rem) {
  input[type="search"] {
    height: calc(2.75rem + 0.625
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media screen and (min-width: 93.75rem) {
  input[type="search"] {
    height: 3.375rem;
  }
}
@media screen and (min-width: 30rem) {
  .offcanvas_search input {
    width: 100%;
  }
}
@media screen and (min-width: 35rem) {
  .social_media_links {
    right: 3em;
    top: 12em;
  }
}
@media screen and (min-width: 30rem) {
  .social_media_share a.print {
    display: block;
  }
}
@media screen and (min-width: 30rem) {
  .steuernews_article_overview .description .sub_title {
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 47.5rem) {
  .news_image {
    display: inline-block;
    float: right;
  }
}
@media screen and (min-width: 93.75rem) {
  .team_overview .entry {
    -webkit-box-align: start;
            align-items: flex-start;
    margin-bottom: 2em;
    overflow: hidden;
  }
  .team_overview .entry:hover .info,
  .team_overview .entry:focus .info {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  .team_overview .entry:hover .info .title,
  .team_overview .entry:focus .info .title {
    margin-top: 0.5em;
  }
  .team_overview .entry:hover .info .title a,
  .team_overview .entry:focus .info .title a {
    text-decoration: underline;
  }
}
@media screen and (min-width: 61.875rem) {
  .team_overview .entry .info {
    text-align: left;
    width: 100%;
  }
}
@media screen and (min-width: 93.75rem) {
  .team_overview .entry .info {
    background-color: rgba(255, 255, 255, 0.9);
    left: 0;
    bottom: 0;
    padding: 0.66666667em;
    position: absolute;
    right: 0;
    -webkit-transform: translateY(60%);
            transform: translateY(60%);
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .team_overview .entry .info:after {
    background-image: url("../images/leaf_main.svg");
    background-position: center center;
    background-size: cover;
    content: "";
    display: block;
    height: 3.125rem;
    opacity: 0.2;
    position: absolute;
    right: 0rem;
    top: 0.625rem;
    width: 3.125rem;
    z-index: -1;
  }
  .team_overview .entry .info strong {
    margin-right: 2em;
  }
}
@media screen and (min-width: 18.75rem) {
  .team_overview .entry .info .title {
    font-size: calc(1rem + 0.125
                            * (100vw - 18.75rem)
                            / (75));
  }
}
@media screen and (min-width: 93.75rem) {
  .team_overview .entry .info .title {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 93.75rem) {
  .team_overview .photo img {
    width: 100%;
  }
}
@media screen and (min-width: 93.75rem) {
  .team_overview .photo {
    margin-bottom: 0;
    width: 100%;
  }
}
@media screen and (min-width: 35rem) {
  .team_entry .photo {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    margin-bottom: 1em;
  }
}
@media screen and (min-width: 75rem) {
  .team_entry .photo {
    display: block;
  }
}
@media screen and (min-width: 75rem) {
  .team_entry .info {
    -webkit-box-align: start;
            align-items: flex-start;
  }
}
@media screen and (min-width: 35rem) {
  .team_entry .info .contact {
    width: 100%;
  }
}
@media screen and (min-width: 75rem) {
  .teaser_overview .teaser {
    margin: 1em 0 5.625rem;
  }
}
@media screen and (min-width: 35rem) {
  .teaser_overview .teaser a {
    -webkit-box-align: start;
            align-items: flex-start;
  }
}
@media screen and (min-width: 61.875rem) {
  .swiper-container {
    margin: -10em auto 0 auto;
    padding: 0 0 4em 0;
  }
}
@media screen and (min-width: 30rem) {
  .swiper-slide .description .sub_title {
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 61.875rem) {
  .swiper-pagination {
    padding-left: 0;
  }
}
@media print {
  .company_logo {
    display: block;
    margin-bottom: 1em;
    max-width: 11.25rem !important;
  }
  .company_logo img {
    display: block;
  }
  .hideme_print,
  .cms_live_management {
    display: none !important;
  }
  .showme_print {
    display: inherit !important;
    height: auto !important;
    max-height: none !important;
  }
  * {
    background: transparent !important;
    border-color: black !important;
    color: black !important;
    text-shadow: none !important;
  }
  *:before {
    color: black !important;
  }
  @page {
    margin: 2cm;
  }
  .contact_wrapper .postal_address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact strong {
    min-width: 5.3125rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  .google_maps .distance::before {
    content: " - ";
    display: inline;
  }
  main a {
    text-decoration: underline;
  }
  main a.touch_link {
    margin-bottom: 2em;
    page-break-inside: avoid;
  }
  .table {
    overflow-y: inherit;
  }
  tr {
    page-break-inside: avoid;
  }
  dl {
    margin: 0;
  }
  dt {
    font-weight: 700;
    margin-bottom: 1em;
  }
  dd {
    margin: 0;
  }
  [role="tooltip"] {
    display: none;
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  body {
    display: block;
    font-size: 85%;
    overflow-x: visible;
  }
  .page {
    display: block;
  }
  main {
    padding-top: 2em;
  }
  .qr_code_print {
    border-top: 0.0625rem solid;
    margin: 2em 0 0;
    padding: 1em 0 0;
    page-break-inside: avoid;
  }
  .qr_code_print:after {
    clear: both;
  }
  .qr_code_print:after,
  .qr_code_print:before {
    content: "";
    display: table;
  }
  .qr_code_print img {
    border: 0.0625rem solid black;
    float: left;
  }
  .qr_code_print p {
    margin-left: 10rem;
  }
}
